
import Vue from 'vue'
import { mapState } from 'vuex'
import Button from '../buttons/Button.vue'
import { PutWithdrawal } from '@/api/accountAPI/UsersAPI'

export default Vue.extend({
  name: 'Dialog3',
  components: { Button },
  props: {
    reason: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    Withdrawal: true,
  }),
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  methods: {
    closeGoDibs() {
      this.$router.push({ name: 'Dibs', query: { acitve: 'dibs' } })
    },
    async openConfirm() {
      try {
        await PutWithdrawal({
          userType: this.user.userType,
          reason: this.reason,
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.$emit('showAlert')
      }
    },
  },
})
