
import Vue from 'vue'
import InputField from '../../common/fields/Input.vue'
import Withdrawal from '../../common/dialogs/Withdrawal.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import Alert from '@/components/common/dialogs/Alert.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CellookButton, InputField, Withdrawal, Alert },
  data: () => ({
    Withdrawal: false,
    onConfirm: false,
    reason: '',
    alert: false,
  }),
  methods: {
    openConfirm() {
      this.onConfirm = !this.onConfirm
    },
    showAlert() {
      this.alert = true
    },
    closeAlert() {
      this.alert = false
      this.$logout()
      this.$router.push('/')
    },
  },
})
interface Data {
  Withdrawal: boolean
  onConfirm: boolean
  reason: string
  alert: boolean
}
interface Methods {
  openConfirm(): void
  showAlert(): void
  closeAlert(): void
}
interface Computed {}
interface Props {}
