
import Vue from 'vue'

//event
// onChange : 변경된 상태값
export default Vue.extend({
  name: 'Input',
  props: {
    icon: {
      type: String as () => string,
      default: () => '',
    },
    clearable: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    suffix: {
      // 마지막에 들어갈 문구? TODO
      type: String as () => string,
      default: () => '',
    },
    desc: {
      // 상단 참조글
      type: String as () => string,
      default: () => '',
    },
    placeholder: {
      // 안내글
      type: String as () => string,
      default: () => 'placeholder',
    },
    color: {
      type: String as () => string,
      default: () => 'default_t',
    },
    backgroundColor: {
      type: String as () => string,
      default: () => '#F7F8F9',
    },
    width: {
      // 가로 사이즈
      type: String as () => string, // Number [Number, String]
      default: () => null,
    },
    height: {
      // 세로 사이즈
      type: String as () => string,
      default: () => null,
    },
    readonly: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    value: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: '100',
    },
  },
  methods: {
    onTextChange(val: string) {
      this.$emit('input', val)
    },
  },
})
